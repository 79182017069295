import { useContext } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { useConnectWallet, useSetChain } from '@web3-onboard/react'
import { displayAddr } from 'utils/formatDisplayAddress'
import { Network } from '../constants'
import { utils } from 'ethers';
import { Paragraph } from 'styling/styles'
import { NamedColors } from 'styling/colors'

export const HeaderWalletButton = () => {
  const [{chains, connectedChain, settingChain}, setChain] = useSetChain()
  const [{ wallet, connecting }, connect] = useConnectWallet()
  const errorChainId = connectedChain && !chains.map((v: any) => v.id).includes(connectedChain.id)
  return (
    <ButtonContainer>
      {!errorChainId &&<Button onClick={() => (connect())}>
         <Paragraph autoCapitalize="yes" color={NamedColors.WHITE}>
          {connecting ? 'Connecting' : wallet ? displayAddr(wallet.accounts[0].address) : 'Connect'}
        </Paragraph>
      </Button>
      }
      {errorChainId && <WrongButton onClick={() => (setChain({ chainId: utils.hexValue(Network.Base) }))}>
        <Paragraph autoCapitalize="yes" color={NamedColors.WHITE}>Wrong Network</Paragraph>
      </WrongButton>}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  ${tw`w-6/12 flex`}
`

const Button = styled.button`
  ${tw`w-full px-4 py-4 rounded-bl-2xl float-right bg-radicalRed`}
  ${tw`sm:px-2 sm:py-2`}
  ${tw`hover:bg-radicalRed`}
`
const WrongButton = styled.button`
  ${tw`w-full px-4 py-4 rounded-bl-2xl float-right bg-red1`}
  ${tw`sm:px-2 sm:py-2`}
  ${tw`hover:bg-red1`}
`

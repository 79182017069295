import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useConnectWallet, useSetChain } from '@web3-onboard/react'
import { providers, Signer, utils, ethers } from 'ethers';
import { networkConfig, activeNetworks } from 'config/app'
import { Network, ALCHEMY_PROJECT_ID, INFURA_PROJECT_ID } from '../constants'

// const DEFAULT_RPC_ENDPOINT = `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`
// ALCHEMY_PROJECT_ID
const DEFAULT_RPC_ENDPOINT = `https://mainnet.base.org`

const defaultProvider = new providers.JsonRpcProvider(DEFAULT_RPC_ENDPOINT, {
  chainId: 8453,
  name: 'base'
})

const Web3Context = createContext<{
  address?: string
  wallet: any | null
  onboard?: any
  provider: any
  signer?: any
  networkId: number
  switchNetwork:(networkId:number) => Promise<boolean>
    }>({
      switchNetwork: async () => false,
      wallet: null,
      provider: defaultProvider,
      networkId: Network.Base,
    });

type Props = {
  children?: React.ReactNode;
};

const defaultProps: Props = {
  children: null,
};

const Web3Provider: React.FC = ({ children }: Props) => {
  const [address, setAddress] = useState<string>();
  // const [wallet, setWallet] = useState<any>(null);
  const [onboard, setOnboard] = useState<any>();
  const [provider, setProvider] = useState<providers.Provider>(defaultProvider);
  const [networkId, setNetworkId] = useState<number>(Network.Base);
  const [signer, setSigner] = useState<Signer>();
  const [{ wallet }] = useConnectWallet()
  const [{}, setChain] = useSetChain()

  const updateWallet = useCallback(async (wallet: any) => {
    if (!wallet) {
      setSigner(undefined);
      setProvider(defaultProvider);
      setAddress('');
      return
    };
    const { accounts, chains, provider } = wallet;
    const walletProvider = new providers.Web3Provider(wallet.provider, 'any');
    const network = await walletProvider.getNetwork();
    const walletSigner = walletProvider.getSigner();
    if(activeNetworks.includes(network.chainId)){
      setSigner(walletSigner);
      setProvider(provider);
      setNetworkId(network.chainId);
      setAddress(accounts[0].address);
    }
  }, []);

  useEffect(() => {
    updateWallet(wallet)
  },[wallet])

  const switchNetwork = async (network: number) => {
    return await setChain({ chainId: utils.hexValue(network) });
  };

  return (
    <Web3Context.Provider
      value={{
        address,
        wallet,
        provider,
        signer,
        networkId,
        switchNetwork,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

Web3Provider.defaultProps = defaultProps;

export { Web3Provider };

export default Web3Context;
import styled from 'styled-components'
import tw from 'twin.macro'

const Box = styled.div`
  ${tw`flex flex-col sm:flex-row items-center justify-center w-full gap-4`}
`

export const MenuLinks = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box>
      <a href="https://rebasebase.com">1stBase</a>
      <a href="https://wrap.rebasebase.com">Wrapper</a>
      <a href="https://volcano.rebasebase.com">Volcano</a>
    </Box>
  )
}

import { AppContext } from 'context/AppContext'
import { useContext } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { Mode } from '../constants'
import { HeaderWalletButton } from './HeaderWalletButton'
import { HeaderToggle } from './HeaderToggle'
import { HeaderNetworkSelect } from './HeaderNetworkSelect'
import RebaseLogo from 'assets/rebase.png'
import { MenuLinks } from './MenuLinks'

export const Header = () => {
  const { mode, toggleMode } = useContext(AppContext)
  return (
    <Container>
      <LeftContainer>
        <LogoSpan>
          <img src={RebaseLogo} alt="Logo" />
        </LogoSpan>
        <Hidden>
          <MenuLinks isMobile={false} />
        </Hidden>
      </LeftContainer>
      <MiddleContainer>
        <Mobile>
          <MenuLinks isMobile={false} />
        </Mobile>
        <HeaderToggle enabled={mode === Mode.VAULTS} toggle={toggleMode} options={['Volcano View', 'Vault View']} />
      </MiddleContainer>
      <RightContainer>
        <HeaderNetworkSelect />
        <HeaderWalletButton />
      </RightContainer>
    </Container>
  )
}

const Container = styled.div`
  ${tw`shadow-sm flex flex-wrap py-1 -mt-1 h-fit`}
`

const Hidden = styled.div`
  ${tw`hidden sm:flex items-center justify-center w-full`}
`
const Mobile = styled.div`
  ${tw`flex sm:hidden items-center justify-center w-full pt-2 pb-10`}
`

const LogoSpan = styled.span`
  font-family: 'Coromont Garamond';
  img {
    width: 40px;
    height: 40px;
  }
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${tw`p-3 text-3xl`}
  ${tw`header-wrap:ml-20`}
`

const LeftContainer = styled.div`
  ${tw`flex w-auto`}
  ${tw`header-wrap:w-4/12`}
`

const MiddleContainer = styled.div`
  ${tw`flex flex-col xl:flex-row items-center justify-center w-full order-3 py-6`}
  ${tw`header-wrap:py-0 header-wrap:max-w-830px header-wrap:mx-auto header-wrap:order-2 header-wrap:w-1/3 xl:w-4/12`}
`
const RightContainer = styled.div`
  ${tw`ml-auto order-2 w-auto flex flex-wrap`}
  ${tw`header-wrap:ml-0 header-wrap:order-3 header-wrap:w-4/12`}
`

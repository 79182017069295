import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import {  utils } from 'ethers';
import { networkConfig } from 'config/app'
import { AppContextProvider } from 'context/AppContext'
import { Body } from 'components/Body'
import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import { VaultContextProvider } from 'context/VaultContext'
import { GeyserContextProvider } from 'context/GeyserContext'
import { Web3Provider } from 'context/Web3Context'
import { SubgraphProvider } from 'context/SubgraphContext'
import { WalletContextProvider } from 'context/WalletContext'
import { StatsContextProvider } from 'context/StatsContext'
import { DropdownsContainer } from 'components/DropdownsContainer'
import { Network } from './constants'

import RebaseLogo from 'assets/rebase.svg'

const injected = injectedModule()
const chains = [
  {
    id: utils.hexValue(networkConfig[Network.Base].id),
    token: 'ETH',
    label: 'Base',
    rpcUrl: networkConfig[Network.Base].rpcUrl
  },
]
const appMetadata = {
  name: 'Rebase Volcano',
  icon: RebaseLogo,
  logo: RebaseLogo,
  description: "Rebase Volcano",
  recommendedInjectedWallets: [
    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    { name: 'MetaMask', url: 'https://metamask.io' }
  ]
}
const initOnboard = init({
  wallets: [injected],
  theme: 'light',
  chains,
  appMetadata,
  connect: {
    autoConnectLastWallet: true
  }
});
function App() {
  return (
    <AppContextProvider>
      <Web3OnboardProvider web3Onboard={initOnboard}>
        <Web3Provider>
          <SubgraphProvider>
            <GeyserContextProvider>
              <VaultContextProvider>
                <WalletContextProvider>
                  <StatsContextProvider>
                    <Header />
                    <DropdownsContainer />
                    <Body />
                    <Footer />
                  </StatsContextProvider>
                </WalletContextProvider>
              </VaultContextProvider>
            </GeyserContextProvider>
          </SubgraphProvider>
        </Web3Provider>
      </Web3OnboardProvider>
    </AppContextProvider>
  )
}

export default App

import styled from 'styled-components/macro'
import tw from 'twin.macro'
import moment from 'moment';
import { safeNumeral } from 'utils/numeral'
import { ResponsiveText } from 'styling/styles'
import { useContext } from 'react'
import { GeyserContext } from 'context/GeyserContext'
import { Dropdown } from './Dropdown'

export const GeysersList = () => {
  const { geysers, selectGeyserByName, selectedGeyserInfo: { geyser: selectedGeyser }, getGeyserName, lastRebaseTime, marketcap } = useContext(GeyserContext)
  const handleGeyserChange = (geyserName: string) => selectGeyserByName(geyserName)
  console.log(geysers)

  const inActive = ["0xe930c5117de70a41ac8379fef4e0ce9599adf34c"] // Mt. Fuji

  const optgroups = (() => {
    const activeGeysers = geysers
      .filter(g => g.active === true && !inActive.includes(g.id))
      .map(({ id }) => getGeyserName(id))
    const inactiveGeysers = geysers
      .filter(g => !(g.active === true) || inActive.includes(g.id))
      .map(({ id }) => getGeyserName(id))
    return [
      {
        group: 'Active Volcanos',
        options: activeGeysers,
      },
      {
        group: 'Inactive Volcanos',
        options: inactiveGeysers,
      },
    ]
  })()

  return (
    <>
      {geysers.length > 0 && (
        <GeysersListContainer>
          <Heading>Last Rebase Time: {lastRebaseTime !== 0 ? `${moment(lastRebaseTime*1000).format('YYYY-MM-DD HH:mm:ss')}` : '-'}</Heading>
          <Heading>Rebase Market Cap: {marketcap !== 0 ? `${safeNumeral(marketcap, '0,0.00')} USD` : '-'}</Heading>
          <br/>
          <Heading>
            <Label>Volcano</Label>
          </Heading>
          <Dropdown
            optgroups={optgroups}
            selectedOption={getGeyserName(selectedGeyser ? selectedGeyser.id : geysers[0].id)}
            onChange={handleGeyserChange}
          />
        </GeysersListContainer>
      )}
    </>
  )
}

const GeysersListContainer = styled.div`
  ${tw`my-3`}
  ${tw`mx-5 sm:mx-10 xl:mx-5`}
`

const Heading = styled.div`
  ${tw`flex flex-row`}
`

const Label = styled.span`
  ${ResponsiveText}
  ${tw`tracking-wider`}
`

import styled from 'styled-components/macro'
import tw from 'twin.macro'

export const Footer = () => (
  <Container>
    <LeftContainer />
    <MiddleContainer>
      <LinkSpan>
        <a className="text-link" href="https://docs.rebasebase.com/overview/introduction-to-rebase">
          Docs
        </a>
        {' | '}
        <a className="text-link" href="https://twitter.com/rebasebase">
          Twitter
        </a>
        {' | '}
        <a className="text-link" href=" https://dune.com/trendyyy/rebase">
          Dune Dashboard{' '}
        </a>
        {' | '}
        <a className="text-link" href="https://app.uniswap.org/explore/tokens/base/0x3421cc14F0e3822Cf3B73C3a4BEC2A1023b8d9Cf">
          Get Rebase
        </a>
        {' | '}
        <a className="text-link" href="https://infusion.finance/liquidity">
          Get 1stBase
        </a>
      </LinkSpan>
    </MiddleContainer>
    <RightContainer />
  </Container>
)

const Container = styled.div`
  ${tw`shadow-sm flex flex-wrap py-1 -mt-1 h-fit`}
`

const LeftContainer = styled.div`
  ${tw`flex w-auto`}
  ${tw`header-wrap:w-2/12`}
`

const MiddleContainer = styled.div`
  ${tw`flex flex-col xl:flex-row items-center justify-center w-full order-3 py-6`}
  ${tw`header-wrap:py-0 header-wrap:max-w-830px header-wrap:mx-auto header-wrap:order-2 header-wrap:w-1/3 xl:w-7/12`}
`

const RightContainer = styled.div`
  ${tw`ml-auto order-2 w-auto`}
  ${tw`header-wrap:ml-0 header-wrap:order-3 header-wrap:w-2/12`}
`

// NOTE: this is hot fix!
// Remove !important
const LinkSpan = styled.span`
  ${tw`ml-2 p-3`}
  ${tw`header-wrap:ml-20`}
  font-size: 20px;
  margin: 0px !important;
`
